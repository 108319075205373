<template>
    <div class="close-header-title no-con">
        <section class="privacy-section">
            <div class="container">
                <div class="main-heading">
                    <h1>About cheapcomforts.com™ </h1>
                    <p>We search the top sites to help travelers book the perfect cheap Comforts </p>
                </div>
                <div class="content-section center">
                    <h2>The easiest way to find a cheap Comforts </h2>
                    <p>We love cheapcomforts, but not how time consuming and complex it can be to find the right one.
                        So we reimagined a better way. We eliminated the need to search across several different sites
                        and scroll through pages of irrelevant results just to find the perfect home away from home.</p>

                    <p>We bring all the rental options from the leading travel sites together in one place and showcase
                        only the best results.
                    </p>

                    <h2>Instant search</h2>
                    <p>No wasted time searching and scrolling across different sites
                    </p>
                    <h2>Personalized results
                    </h2>
                    <p>Only the best results so you can easily find the perfect rental</p>
                    <h2>Millions of rentals
                    </h2>
                    <p>The best Cheapcomforts from the world’s largest travel sites

                    </p>
                    <h2>Easy to search
                    </h2>
                    <p>We care about one thing: finding you the perfect Cheapcomforts. We search the top booking sites
                        so you don’t have to.</p>
                    <h2>Easy to list
                    </h2>
                    <p>Join the millions of properties already listed on our site. Cheapcomforts is the easiest way for
                        travelers to find your property, compare the top results and make a faster booking decision.</p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'about_us',
    components: {
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 5px !important;
}
</style>